<footer id="footer">
  
  <div class="container pr-z2 z-index-10">
    <div class="row">      
      <div class="text-center footBrand">
        <img src="../../../assets/images/fonttech final.png" />
      </div>
      <div class="footMenu">
        <ul>
          <li>
            <a (click)="onClick('HOME')" class="hover:underline text-sm cursor-pointer">Home</a>
          </li>
          <!-- <li>
            <a (click)="onClick('About')" class="hover:underline text-sm cursor-pointer">About</a>
          </li> -->
          <li>
            <a (click)="onClick('FRANCHISE')" class="hover:underline text-sm cursor-pointer"> Franchise </a>
          </li>
          <li>
            <a (click)="onClick('PRODUCT')" class="hover:underline text-sm cursor-pointer">Our Product</a>
          </li>
        
          <li>
            <a (click)="onClick('CONTACT')" class="hover:underline text-sm cursor-pointer">Contact Us</a>
          </li>
        </ul>
      </div>
      <div class="social">
        <ul> 
          <li>
            <a href="https://www.facebook.com/profile.php?id=61557638521830"><i class="bi bi-facebook"></i></a>
          </li>
          <li>
            <a href="https://x.com/FonttechRetail"><i class="bi bi-twitter-x"></i></a>
          </li>
          <li>
            <a href="https://www.instagram.com/fonttech_retail"><i class="bi bi-instagram"></i></a>
          </li>
          <li>
            <a href="https://www.youtube.com/@FonttechRetail"><i class="bi bi-youtube"></i></a>
          </li>
        </ul>
      </div>     
    </div>
  </div>
<div class="foot-top-border pr-z2 z-index-10">
  <div class="container">
    <div class="row">
      <div class="copy-term">
        <div class="copy">
          <span>© 2024 <a href="#" class="hover:underline">FontTech™</a>. All Rights Reserved. </span>  
        </div>
        <div class="term">
          <ul>
            <li>
              <a (click)="onClick('PRIVACY')" class="hover:underline text-sm cursor-pointer">Privacy</a>
            </li>
            <li>
              <a (click)="onClick('TERMS')" class="hover:underline text-sm cursor-pointer">Terms Of Use</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
  
  <!--Effect Start-->
  <div class="footbg">
    <div class="footbg-ani">
      <div class="bird-container bird-container--one">
        <div class="bird bird--one"></div>
      </div>
      
      <div class="bird-container bird-container--two">
        <div class="bird bird--two"></div>
      </div>
      
      <div class="bird-container bird-container--three">
        <div class="bird bird--three"></div>
      </div>
      
      <div class="bird-container bird-container--four">
        <div class="bird bird--four"></div>
      </div>
      
    </div>
    
  </div>
  <!--Effect End-->
</footer>
