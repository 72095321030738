<section class="pt-15 pb-15">
	<div class="container">
		<div class="row">
			<div class="rgtContent">
				<h2 class="heading-02">Terms of Use</h2>
				<div class="rgt-prag">
          <p><strong>Definitions</strong></p>
      <p>
        "Agreement" means the terms and conditions as detailed herein including
        all schedules, appendices, annexures, references to this agreement as
        amended, novated, supplemented, varied or replaced from time to time.
      </p>
      <p>
        "User" means and includes any person or an entity or any legal entity
        using or accessing the services, means and includes any
        goods/merchandise/products/services/offers/display items that are
        uploaded/shown, the related description, information, procedure,
        processes, warranties, delivery schedule, etc.
      </p>
      <p>
        "Section 2(v) of the Information Technology Act 2000", "Information"
        includes data, text, images, sounds, codes, computer programs, software
        and databases or micro film or computer generated micro fiche.
      </p>
      <p><strong>Legal Notices</strong></p>
      <p>
        The terms and conditions contained herein along with the privacy policy
        form an agreement regulating our relationship with regard to the use of
        the website of the user. By using the site and downloading materials
        from the site, the user agrees to follow the conditions set forth in
        this notice. By accessing this site, the user indicates to agree to be
        bound by the terms and conditions. Fonttech reserves the
        right, in its sole discretion, to terminate the access of the user to
        the services offered or any portion thereof at any time, without notice.
      </p>
      <p>
        Fonttech may terminate the authorization, rights and licence
        given at any time and, upon such termination, the user agrees to
        immediately destroy all materials.
      </p>
      <p><strong>Eligibility</strong></p>
      <p>
        The user represents and warrants that the user is competent and eligible
        to enter into a legally binding agreement that the user shall not use
        this site if the user is not competent to contract under the applicable,
        rules and regulations.
      </p>
      <p><strong>Governing Law and Jurisdiction</strong></p>
      <p>
        This agreement is governed and construed in accordance with the laws of
        the Union of India. The user hereby irrevocably consents to the
        exclusive jurisdiction and venue of Courts in New Delhi, India with
        respect to all disputes relating to or arising out of the use of the
        Fonttech site/services. Use of the site/services is
        unauthorized in any jurisdiction that does not give effect to all
        provisions of these terms and conditions, including without limitation
        this paragraph.
      </p>
      <p><strong>Links to Non-Fonttech Sites</strong></p>
      <p>
        Fonttech does not undertake to monitor or review third party
        site content nor is Fonttech responsible for the accuracy of
        any such third party websites. Furthermore, Fonttech may
        provide on the site links to websites operated by non Fonttech
        entities. If the user visits any linked site, the user shall do so at
        his/her own risk and it shall be the responsibility of the user to take
        all protective guard against viruses or other destructive elements. Fonttech
        makes no warranty or representation regarding any link or
        web information appearing thereon or any of the products or services
        described thereon. Links do not imply that Fonttech endorses
        or is affiliated or associated with or is legally authorised to use any
        trademark, trade name, logo or copyright symbol of Fonttech or
        any of its affiliates or subsidiaries.
      </p>
      <p><strong>Liability Clause</strong></p>
      <p>
        Fonttech has made its site/services available to use as a
        matter of convenience. Fonttech holds no responsibility, and
        shall not be liable for any damages to the computer equipment and/or
        other property of the user that may ensue on account of access to/use of
        the site or your downloading of, including but not limited to, any data,
        material, text, images, etc. from the site.
      </p>
      <p>
        Fonttech makes no warranties or representation as to its
        accuracy and Fonttech specifically does not assume any
        liability or responsibility for any errors or omission in the content on
        the site. In no event, shall Fonttech nor any other party
        involved in creating, producing or delivering the site shall be liable
        for any direct, indirect, punitive, incidental, special or consequential
        damages or for any damages whatsoever including, without limitation
        damages for loss of use, data or profits, arising out of, in any way,
        connected with the use or performance of the Fonttech
        site/services.
      </p>
      <p>
        This clause shall survive even after discontinuance of the said website.
      </p>
      <p><strong>Security</strong></p>
      <p>
        Fonttech strictly prohibits the â€“ (i) use of any device or
        software that may interfere with the operations of the site; or (ii) do
        any act that may impose unnecessary or unreasonable hindrance upon the
        infrastructure of the site (such as sending mass e-mails 'ie'
        "spamming"); or (iii) tamper with the software of the site or the
        functionality thereof. This may include, however, not limited to putting
        material on the site, which may be carrying any virus(s) or other
        elements that may damage, interfere or obstruct the programming
        structure of the site.
      </p>
      <p><strong>Indemnity</strong></p>
      <p>
        The user hereby agrees to indemnify and hold Fonttech, its
        subsidiaries, affiliates, directors, officers and employees harmless
        from any claim, demand or damage, including reasonable attorney's fees,
        asserted by any third party due to or arising out of the of or conduct
        on the Fonttech site/services by the user.
      </p>
      <p>
        Without prejudice to the limitation of liability set forth herein, the
        user agrees to take all reasonable steps to mitigate the user's loss
        that may arise on account of any action or inaction on the part of the
        user, including but not limited to negligence, libel, defamation,
        violation of rights of privacy or publicity, intellectual property
        rights, breach of contract or otherwise, which the user may bring
        against Fonttech.
      </p>
      <p><strong>User's Material/Information</strong></p>
      <p>
        Fonttech may receive and collect certain information in
        standard usage logs through identification information obtained from
        "cookies", sent to the user's browser from a:
      </p>
      <ul class="franchiseList mb-4">
        <li>Web server cookie stored on the user's hard drive;</li>
        <li>An IP address, assigned from the user's computer;</li>
        <li>
          The domain server through which the user access Fonttech's
          &nbsp;services;
        </li>
        <li>The type of computer used by the user;</li>
        <li>The type of web browser used by the user;</li>
        <li>Name including first and last name;</li>
        <li>Alternate e-mail address;</li>
        <li>Mobile phone number and contact details;</li>
        <li>Zip/Postal code;</li>
        <li>Opinions of features on Fonttech's website;</li>
        <li>About the pages that the user visits;</li>
        <li>The links the user clicks on Fonttech's site; etc.</li>
      </ul>
      <p>
        Fonttech through the privacy policy associated with this site
        seeks to protect any personally identifiable material or information
        that the user may provide on the site. The material/information provided
        on the site is either the property of, or used with permission by, Fonttech.
        Any unauthorized use of the images on the site may
        violate copyright laws, trademark laws, the laws of privacy and
        publicity, and/or the civil and criminal statutes.
      </p>
      <p>
        The user further agrees that by providing any material or information to
        Fonttech, the user shall fully assign and transfer to Fonttech
        all intellectual property rights in the material or
        information and that Fonttech is thereby free to use the same
        whether before or after any modification thereof. The user also agrees
        that Fonttech shall be free to use any ideas, concepts or
        know-how that the user may provide to Fonttech.
      </p>
      <p><strong>Changes</strong></p>
      <p>
        Fonttech assumes all the rights, at its sole discretion, to
        change, alter, add or remove any portion of this agreement in whole or
        in part at any time. However, changes in this agreement shall be
        effective when the same is notified on the site. The user agrees that
        the continued use of the site after such change or alteration to this
        agreement will be presumed as acceptance of such change or alteration.
      </p>
      <p>
        &nbsp;Fonttech further reserves the right to impose limits on
        certain features or suspend the user's access to parts or the entire
        site without any notice or liability.
      </p>
      <p><strong>Disclaimer</strong></p>
      <p>
        Fonttech expressly disclaims any warranties whether expressed
        or implied about the suitability, reliability, availability,
        truthfulness, quantity, continuity, performance lach of viruses or other
        harmful components and accuracy of the information, software, products,
        services and related graphics contained in the site for any purpose.
        Fonttech does not warrant the absolute completeness of the
        material or the reliability of any other information displayed or
        distribution through the site. The user acknowledges that any reliance
        on any such information shall be at his/her sole risk and liability.
      </p>
      <p>
        Fonttech expressly disclaims any representations or warranties
        of any kind and conditions with regard to their service, software,
        products and related graphics including all implied warranties and
        conditions of merchantability.
      </p>
      <p>
        In no event, shall Fonttech be liable for any direct,
        indirect, consequential, punitive, special or interim damages resulting
        from or arising out of or in connection with the use of the site or in
        connection with this agreement
      </p>
      <p>
        Fonttech shall not be responsible for any loss, damage, cost, expense or
        other consequences/liability suffered by the Consumer, whatsoever.
        Notwithstanding anything contained herein, aggregate liability of Fonttech to
        a Consumer for any reason whatsoever shall not exceed the value of the
        respective Offer(s) given to the Consumer. Fonttech shall not be liable for
        any loss or damage due to Acts of God, state governmental actions,
        policies and/or its subsequent changes, restrictions imposed and any
        other force majeure conditions. In case of any dispute, decision of Fonttech
        shall be final and binding on the Consumer. The Offer shall be governed
        by laws of India and courts at Delhi shall have sole and exclusive
        jurisdiction to resolve any matter and dispute relating to or arising
        under this Offer.
      </p>
        </div>
      </div>
    </div>
  </div>
</section>
