<nav class="topbar-bg sticky" style="z-index: 1000;" (click)="modalStopPropagation($event)">
	<div class="container flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
		<div class="flex float-left flex-row">
			<button data-collapse-toggle="mobile-menu-2" type="button"
				class="md:hidden focus:outline-none dark:text-white togal-menu" 
				aria-controls="mobile-menu-2" aria-expanded="false">
				<span class="sr-only">Open main menu</span>
				<svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd"
						d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
						clip-rule="evenodd"></path>
				</svg>
				<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd"
						d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						clip-rule="evenodd"></path>
				</svg>
			</button>
			<a href="" class="brand">
				<img src="../../../assets/images/fonttech final.png">
			</a>
		</div>
		<div class="hidden justify-between items-center w-full md:flex md:w-auto md:order-1" (click)="collapseMenu()">
			<ul class="flex flex-col  font-medium md:flex-row md:space-x-8 md:mt-0 mb-0" *ngFor="let item of menuArr;">
				<!-- <li class="nav-item">
					<a [routerLink]="item.link" class="nav-link">
						Home
					</a>
				</li>
				<li class="nav-item">
					<a [routerLink]="item.link" class="nav-link">
						About Us
					</a>
				</li> -->
				<li class="nav-item">
					<a [routerLink]="item.link" class="nav-link">
						{{item.name}}
					</a>
				</li>
				<!-- <li class="nav-item">
					<a [routerLink]="item.link" class="nav-link">
						Our Products
					</a>
				</li>
				<li class="nav-item">
					<a [routerLink]="item.link" class="nav-link">
						Contact Us
					</a>
				</li> -->
			</ul>
		</div>
		<div class="flex items-center md:order-1">
			<div (click)="onClick('LOGIN')"
				class="cursor-pointer relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full ">
				<svg class="absolute w-12 h-12 text-gray-400 left-0" fill="currentColor" viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
						clip-rule="evenodd"></path>
				</svg>
			</div>
			<div *ngIf="false"
				class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full dark:bg-gray-600">
				<span class="font-medium text-gray-600 dark:text-gray-300">JL</span>
			</div>
		</div>
	</div>
	<div class="hidden justify-between items-center md:hidden w-full md:w-auto md:order-1" id="mobile-menu-2"  (click)="collapseMenu()">
		<ul class="flex flex-col font-medium md:flex-row md:space-x-8 md:mt-0" *ngFor="let item of menuArr;">
			<!-- <li class="nav-item">
				<a [routerLink]="item.link" class="nav-link">
					{{item.name}}
				</a>
			</li> -->

			<!-- <li class="nav-item">
				<a [routerLink]="item.link" class="nav-link">
					Home
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="item.link" class="nav-link">
					About Us
				</a>
			</li> -->
			<li class="nav-item">
				<a [routerLink]="item.link" class="nav-link">
					{{item.name}}
				</a>
			</li>
			<!-- <li class="nav-item">
				<a [routerLink]="item.link" class="nav-link">
					Our Products
				</a>
			</li>
			<li class="nav-item">
				<a [routerLink]="item.link" class="nav-link">
					Contact Us
				</a>
			</li> -->
		</ul>
	</div>
</nav>