<section class="pt-15 pb-15">
	<div class="container">
		<div class="row">
			<div class="rgtContent">
				<h2 class="heading-02">Privacy Policy</h2>
				<div class="rgt-prag">
				  <!-- <p><strong>Overview :</strong></p> -->
				  <p>
					<span class="text_gradient font-medium">FontTech</span> ("Fonttech" or "We" or "Us" or "Our") owns and
					  operates <a href="/">www.fonttech.in</a> ("Website"). We
					  respect and understand the importance of protecting User's (You, Your)
					  privacy. We are committed to safeguarding the privacy of the personal data
					  or information ("Info") that We collect, possess, use, process, record,
					  store, transfer, disclose, deal, handle and receive from You while
					  rendering services in connection with Our businesses. Accordingly,
					  following is Our Privacy Policy which along with various guidelines, sets
					  out how We intend to safeguard such Info received and used by Us as
					  aforesaid. This Privacy Policy is in compliance with the prevailing
					  Information Technology Act, 2000 and applicable rules made thereunder ("IT
					  Act").
				  </p>
				  <p><strong>TYPES OF PERSONAL INFORMATION WE COLLECT</strong></p>
				  
				  <p>
					The term &amp;quot;personal information&amp;quot; (Info) in this
					Policy refers to personal information which does identifies You and/or is
					capable of identifying You as an individual. The types of Info that We may
					collect consist of following personal information relating to You:
				  </p>
				  <p><strong>Our Offerings :</strong></p>
				  <ul class="franchiseList mb-4">
					<li>Name</li>
					<li>address</li>
					<li>mobile number</li>
					<li>IP address</li>
					<li>e-mail address</li>
					<li>
					  any detail relating to the above clauses as provided to Us for providing
					  service.
					</li>    
				  </ul>
				  <p>
					Provided that any information that is freely available or accessible in
					public domain or furnished under the Right to Information Act, 2005 or any
					other law for the time being in force shall not be regarded as Info for
					the purposes of this Policy.
				  </p>
				  <p>
					Further, Your Info shall not be disclosed to any third person unless the
					said third person is required to have Your Info to provide required
					services to You and/or to help investigate, prevent or take action
					regarding unlawful and illegal activities, suspected fraud, potential
					threat to the safety or security of any person, violations of Website
					terms of use or to defend against legal claims and special circumstances
					such as compliance with the rules of any, subpoenas, court orders,
					requests/order from legal authorities or law enforcement agencies
					requiring such disclosure. Info provided by You is compiled and analysed
					on an aggregated basis.We respect Your privacy rights, therefore, We will
					observe the following guidelines when collecting, receiving, possessing,
					using, processing, recording, storing, transferring, dealing, handling,
					and disclosing:
				  </p>
				  <ul class="franchiseList mb-4">
					<li>
					  Info will be collected, received, possessed, used, processed, recorded,
					  stored, transferred, dealt, handled and disclosed in compliance with
					  applicable Indian laws;
					</li>
					<li>
					  Info will be collected for specified, legal and legitimate purposes and
					  shall be used for the purpose for which it has been collected;
					</li>
					<li>
					  Info will be relevant/necessary to/for the purposes for which it is
					  collected and used;
					</li>
					<li>
					  Info will be kept only as long as necessary for the purposes for which
					  it was collected and processed; and
					</li>
					<li>
					  Appropriate prescribed measures will be taken to prevent unauthorized
					  access or use, unlawful processing, and unauthorized or accidental loss,
					  destruction, or damage to such Info.
					</li>
				  </ul>
				  <p>
					<strong
					  >PURPOSES FOR COLLECTION, STORAGE AND/OR USE OF PERSONAL DATA OR
					  INFORMATION</strong
					>
				  </p>
				  <p>
					The primary purposes for collection, storage and/or use of Info is for:
				  </p>
				  <ul class="franchiseList mb-4">
					<li>
					  Our business processes, operations and management including but not
					  limited to performance of the business, operation of the services,
					  entering into or performing any contract, maintaining quality of the
					  services, providing support to the products You have obtained from Us;
					</li>
					<li>
					  processing order(s), corresponding with You, fulfilling Your transaction
					  requests and delivering the products requested by You;
					</li>
					<li>
					  providing You with information or products that You request from Us or
					  which We feel may interest You, record keeping and other general
					  administrative and services related processes;
					</li>
					<li>
					  ensuring the safety and protection of Our rights or property or Our
					  business;
					</li>
					<li>
					  complying with applicable legal requirements including but not limited
					  to governmental reporting, etc and fulfilling statutory/legal
					  obligations under applicable laws, adhering to judicial or
					  administrative orders, compliance with laws;
					</li>
					<li>
					  contacting You via surveys to conduct research about Your opinion of
					  current services or of potential new services that may be offered by Us;
					</li>
					<li>
					  monitoring or recording of certain calls, chats and other interactions
					  relating to the online transactions which may involve You calling Us or
					  Us calling You and online chats for staff training or quality assurance
					  purposes or to retain evidence of a particular transaction or
					  interaction;
					</li>
					<li>
					  while conducting daily business/operations such Info may be provided to
					  Our affiliates and associate companies, Our employees/staff and Third
					  Party for the purpose of processing such Info for or on Our behalf
					  including but not limited to helping Us to perform statistical analysis,
					  send You email or postal mail, provide customer support/support
					  services, arrange for deliveries of programs, products, information, and
					  services etc;
					</li>
					<li>direct marketing and promotional purposes;</li>
					<li>
					  operating Website, improving the content of Our Website to offer You
					  better products and/or services and to ensure that content from our
					  Website is presented in the most effective manner for You; and
					</li>
					<li>
					  to help diagnose problems with Our server, and to administer Our
					  Website. Your IP address is also used to help identify You and to gather
					  broad demographic information; and
					</li>
					<li>in connection with Our business.</li>
				  </ul>
				  <p><strong>DATA COLLECTION DEVICES</strong></p>
				  <p>
					In addition to Info, We may use data collection devices such as "cookies"
					or other technology to obtain certain types of information when Your web
					browser accesses Our Website. Cookies are small files containing a string
					of characters to identify Your browser. Cookies allow the Website to
					remember important data or information that will make Your visit to the
					Website more useful. Our Website may use cookies and other technology to
					store pertinent user information during a session to speed navigation and
					keep track of items and to collect anonymous traffic data that We may use
					to enhance Our Website and for marketing and promotional purposes. We also
					use cookies to allow You to enter Your password less frequently during a
					session. Cookies can also help Us in providing information that is
					targeted to Your interests. Most cookies are "session cookies," meaning
					that they are automatically deleted from Your hard drive at the end of a
					session. You are always free to decline Our cookies if Your browser
					permits, although in that case You may not be able to use certain features
					on the Website and You may be required to re-enter Your password more
					frequently during a session. You can reset Your browser to refuse cookies
					or warn You when they are being sent.
				  </p>
				  <p>
					We also use logging systems on Our internal network to register the use of
					Your computer systems. This is done for the purpose of ensuring the
					performance, integrity and security of these systems. We have also
					contracted with Third Party to track and analyze anonymous usage and
					volume statistical information from Our visitors and members for research
					purposes. Such information is shared externally only on an anonymous,
					aggregated basis. Such Third Party use persistent cookies to help Us to
					improve the visitor experience, to manage Our Website content, and to
					track visitor behaviour. All data or information collected by such Third
					Party on Our behalf is used solely by Us or on Our behalf and is shared
					externally only on an anonymous, aggregated basis.
				  </p>
				  <p>
					We will make best efforts to do so but do not warrant that Our Website is
					free of any operational errors nor do We warrant that Our Website will be
					free of any virus, computer contaminant, worm, or other harmful
					components.
				  </p>
				  <p>
					You acknowledge that Our site, its services and contents are provided on
					an "as is" and "as available" basis, without warranties of any kind,
					either express or implied. We expressly disclaims that any service will be
					uninterrupted, timely, secure or error-free for any reasons whatsoever
					including but not limited to overload / breakdown of receiving network,
					servers or applications; system failures out of Our control or due to
					heavy traffic on network".
				  </p>
				  <p><strong>DISCLOSURES OR TRANSFER OF YOUR INFO</strong></p>
				  <p>
					We will disclose or transfer Your Info in accordance with this Policy and
					all applicable legal requirements. Your Info will be disclosed or
					transferred, as may be required from time to time, as follows:
				  </p>
				  <ul class="franchiseList mb-4">
					<li>
					  For Business Purposes: to (i) the appropriate employees/staff/persons in
					  Our offices; (ii) Our affiliates and associate companies; (iii) to Our
					  different offices within India and outside India in accordance with the
					  IT Act; (iv) to any Third Party, in the event of a proposed or actual
					  business transfer; and (v) in connection with Our business and services
					  provided by Us.
					</li>
					<li>
					  To Third Parties: working with Us or on Our behalf in different
					  industries and categories of business. We will disclose, share, transfer
					  or provide Your Info to any Third Party in connection with Our business
					  requirements or for the purposes indicated herein. Such Third Party are
					  required to process Your Info they receive from Us in a lawful, safe and
					  responsible manner in accordance with this Policy and the prevailing
					  laws and take all appropriate security and confidentiality measures such
					  that they do not use Your Info for their own purposes or disclose Your
					  Info to others. Neither We nor any Third Party will publish Your Info.
					</li>
					<li>
					  For Legal Requirement: to any court of law and/or government
					  agencies/entity as may be required under law and/or statutory authority,
					  Reserve Bank of India and Credit Information Bureau India Ltd ("CIBIL")
					  or in response to a legal process, for the purpose of verification of
					  identity, or for prevention, detection, investigation including cyber
					  incidents, prosecution, and punishment of offences and/or to any Third
					  Party by an order under the applicable law or if We determine it is
					  necessary or desirable to comply with any applicable law regulation,
					  legal process or enforceable governmental request or to protect or
					  defend Our rights or property including compliance with accounting and
					  tax rules and regulations or to investigate detect, prevent, or take
					  action regarding illegal activities, suspected fraud, security or
					  technical issues or situations involving potential threats to the
					  physical safety of any person.
					</li>
					<li>
					  For Centralized Data Processing Activities: We have centralized certain
					  aspects of Our data processing and administration in order to allow Us
					  to manage Our business in better ways. Such centralization may result in
					  the transfer of Your Info: (i) from one country to another; (ii) to Our
					  employees/staff of the affiliates/associate companies of Fonttech in other
					  locations etc. However, whenever Your Info is transferred within Fonttech, it
					  will be processed in accordance with the terms and conditions of this
					  Policy.
					</li>
				  </ul>
				  <p><strong>SECURITY PRECAUTIONS</strong></p>
				  <p>
					We have in Our Website, stringent security measures in place to protect
					the loss, misuse, and alteration of the information under Our control.
					Whenever You change or access Your account information, We offer the use
					of a secure server. Once Your information is in our possession We adhere
					to strict security guidelines, protecting it against unauthorized access.
					We take appropriate security measures to protect Your Info against
					unauthorized access, alteration, disclosure or destruction.
				  </p>
				  <p>
					We limit access to Your Info to members of Our team/Our employees /Third
					Party who We believe reasonably need to come into contact with that
					information for the purpose of performing their duties. We have strict
					confidentiality obligations that apply to such members/Our employees
					/Third Party.
				  </p>
				  <p><strong>RETENTION OF YOUR INFO</strong></p>
				  <p>
					We are aware of the importance of timely destruction of Info. We ensure
					that Your Info is not stored/retained for a longer period than necessary
					for the purpose for which it was collected, used or processed or as
					provided in Our contracts except when there is a legal obligation to do so
					under any law. It is our practice to destroy Your Info as soon as possible
					after it is no longer necessary for the purpose for which it was
					collected, used or processed save and except as stated hereinabove.
				  </p>
				  <p>
					<strong
					  >UPDATING OR REVIEWING YOUR PERSONAL DATA OR INFORMATION/ QUESTIONS OR
					  COMPLAINTS</strong
					>
				  </p>
				  <p>
					You can review the Info provided by You any time. We will ensure that any
					change done by You in Your Info shall be included as soon as possible.
				  </p>
				  <p>
					You expressly state that Info provided by You to Us is correct and
					complete in all respects and does not contain any false, distorted,
					manipulated, fraudulent or misleading facts. We expressly disclaim any
					liability arising out of the said data or information provided by You to
					Us. Further, You expressly agree that We are not responsible for the
					accuracy and authenticity of such data or information provided by You to
					Us and You agree to indemnify Fonttech for all losses incurred by Fonttech due to
					any false, distorted, manipulated, defamatory, libellous, vulgar, obscene,
					fraudulent or misleading facts made by You to Fonttech.
				  </p>
				  <p><strong>ENFORCEMENT RIGHTS</strong></p>
				  <p>
					All Our affiliates/group companies will ensure that this Policy is
					observed. All Our employees/staff of and Third Party who have access to
					Info are required to comply with this Policy.
				  </p>
				  <p>
					All Third Party shall only process the Info in accordance with Our
					instructions or make decisions regarding such data or information as part
					of the delivery of their services. In either instance, We will select
					reliable Third Party who undertake, by contract or other legally binding
					and permissible means, to put in place appropriate technical and
					organizational security measures to ensure an adequate level of protection
					of such data or information. We will require Third Party to comply with
					this Policy or to guarantee the same levels of data protection that is
					adhered to by Us when handling/processing such data or information. Such
					selected Third Party will have access to such data or information solely
					for the purposes of performing the services specified in the applicable
					service contract and are legally and contractually bound to maintain the
					privacy of such data or information shared with them and will not disclose
					it further. If We conclude that a Third Party is not complying with these
					obligations, We will promptly take appropriate actions to remedy such
					non-compliance or implement necessary sanction
				  </p>
				  <p>
					Additionally, Our team members/employees/staff are bound by internal
					confidentiality policies. Any team member/employee/staff found to have
					violated this Policy or any other policies will be subject to disciplinary
					action, up to and including termination of employment and/or penalties
					under applicable laws.
				  </p>
				  <p>
					All Third Party and Our team members/employees/staff do hereby
					specifically agree that he/she/it shall, at all times, comply with the
					requirements of the IT Act, while collecting, receiving, possessing,
					using, processing, recording, storing, transferring, dealing, handling and
					disclosing Info. The said Third Party and team members/employees/staff do
					further unequivocally declare that in case he/she/it violates any
					provisions of the IT Act, he/she/it shall alone be responsible for all
					his/her/its acts, deeds and things and that he/she/it alone shall be
					liable for civil and criminal liability there under or under any other law
					for the time being in force.
				  </p>
				  <p><strong>MODIFICATIONS TO THE POLICY</strong></p>
				  <p>
					We reserve the right to update, change or modify this Policy, from time to
					time, without prior notification. The policy shall come into effect from
					the date of such update, change or modification.<br />We will inform You
					regarding any such changes by updating this Policy and will post all
					changes to the Policy on the website.
				  </p>
				  <p>
					Effective with the implementation of this Policy, applicable Fonttech privacy
					guidelines or practices relating to the processing of Info will be
					superseded by the terms of this Policy and modified accordingly. All
					parties to any such agreements will be notified of the effective date of
					implementation of the Policy.
				  </p>
				  <p>
					If any of the terms or definitions used in this Policy is ambiguous, the
					definitions established under the IT Act shall apply.
				  </p>
				  <p><strong>YOUR CHOICES AND SELECTING YOUR PRIVACY PREFERENCES</strong></p>
				  <p>
					We give you the choice of receiving a variety of information that
					complements our products. You can subscribe to receive certain product
					information like new models, upcoming products and Our general
					communications like offers, sale, discount or invitations to participate
					in market research or compliance reviews. We give you a choice regarding
					delivery of Our general communications by postal mail, email, telephone,
					or mobile device.
				  </p>
				  <p>
					Subscription communications include email newsletters, etc. that may be
					expressly requested by You or which You consented to receive. After You
					request such communications, You may opt out of receiving them by using
					one of the following methods:
				  </p>
				  <p>
					Select the email "opt out" or "unsubscribe" link, or follow the opt-out
					instructions included in each email subscription communication.<br />To
					unsubscribe from messages delivered to mobile devices, reply to the
					message with the words "STOP" or "END."
				  </p>
				  <p>
					Be sure to provide Your name, relevant contact information, and specific
					relevant information about Our subscriptions that you no longer wish to
					receive.
				  </p>
				  <p>
					Please be aware that when You opt out of receiving certain subscription
					communications, it may affect the services You have chosen to receive from
					Fonttech where accepting the communications is a condition of receiving the
					services.
				  </p>
				  <p>
					This option does not apply to communications primarily for the purpose of
					administering order completion, contracts, support, product safety
					warnings or other administrative and transactional notices where the
					primary purpose of these communications is not promotional in nature.
				  </p>
				</div>
			  </div>
	
		</div>
	</div>

</section>

