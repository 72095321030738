<div class="rgtContent">
  <h2 class="heading-02">Contact US</h2>
  <div class="rgt-prag">
    <p><strong>You can reach out to us anytime.</strong></p>
    <div class="w-full shrink-0 grow-0 basis-auto lg:w-full">
      <!-- w-7/12 -->
    <div class="flex flex-wrap">
      <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-3/6 ">
        <div class="align-start flex">
          <div class="shrink-0">
            <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
              <img src="../../../assets/images/franchise.svg">
            </div>
          </div>
          <div class="ml-6 grow">
            <p class="mb-2 font-bold text-black">Franchise Enquiry</p>
            <div class="flex flex-col">
              <a href = "mailto:franchise@fonttech.in" class=" cursor-pointer text-black mb-3">
                franchise@fonttech.in
              </a>
              <a [href]="contactNoLink" class=" cursor-pointer text-black mb-3">
                {{contactNo}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-3/6">
        <div class="flex items-start">
          <div class="shrink-0">
            <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
              <img src="../../../assets/images/enquiry.svg">
            </div>
          </div>
          <div class="ml-6 grow">
            <p class="mb-2 font-bold text-black">
              Warranty
            </p>
            <div class="flex flex-col">
              <a href = "mailto:warranty@fonttech.in" class=" cursor-pointer text-black mb-3">
                warranty@fonttech.in
              </a>
              <a [href]="contactNoLink" class=" cursor-pointer text-black mb-3">
                {{contactNo}}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-12 w-full shrink-0 grow-0 basis-auto lg:w-3/6">
        <div class="align-start flex">
          <div class="shrink-0">
            <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">
              <img src="../../../assets/images/customercare.svg">
            </div>
          </div>
          <div class="ml-6 grow">
            <p class="mb-2 font-bold text-black">Customer Care</p>
           <div class="flex flex-col">
            <a href = "mailto:customercare@fonttech.in" class=" cursor-pointer text-black mb-3">
              customercare@fonttech.in
            </a>
            <a [href]="contactNoLink" class=" cursor-pointer text-black mb-3">
              {{contactNo}}
            </a>
           </div>
          </div>
        </div>
      </div>           
    </div>
  </div>
  </div>
</div>  