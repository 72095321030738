<p class="text-red-600 font-bold text-xl">Partnership Opportunities</p>

<p class="mb-4">
  Orient Electric is open to mutually beneficial alliances. We are looking for
  partners who are ready to join hands with us in opening Orient Electric Smart
  Shop in their respective cities.It is a great opportunity for enterprising
  individuals to partner with India's leading electrical goods company. We have
  built a reputation for doing business with our partners in a spirit of trust,
  integrity and collaboration.
</p>

<p class="text-red-600 font-bold text-xl">Your Success is Our Success</p>

<p class="mb-4">
  When you associate with us, you choose an alliance that will continually yield
  impressive returns on your moderate investments. We are committed to
  maximising the prospects of the success of both existing and new franchises.
</p>

<p class="mb-4">
  Under the franchise program, we are offering attractive margins for our
  partners on entire product range of Orient Electric. We continuously assist
  franchisees in monitoring and managing the business to make sure that the best
  business practices get implemented to run the store on profit as well as to
  achieve consumer satisfaction.
</p>
<p class="mb-4">
  Doing most of the heavy lifting, we provide comprehensive training to all
  franchises on service operations, standard check-up procedures, accounting,
  inventory control etc.We take care of inventory management by playing a key
  role in stock supply, control checks on goods delivery, replenishment ordering
  and product range maintenance.
</p>
<p class="mb-4">
  Moreover, we also offer comprehensive branding, advertising and PR support to
  boost visibility for your business. We back our every franchise with dedicated
  personnel to ensure effective communication and smooth operations.
</p>
